
import {ActionContext, ActionTree} from 'vuex';
import {HaciendaState} from './types';
import {HaciendaModel} from './types';
import {RootState} from '@/store/types'
import {AxiosResponse} from 'axios'
import { mainAxios } from '@/plugins/axios';
import Vue from 'vue'
import { mensajes } from '@/plugins/notify';
type HaciendaActionContext=ActionContext<HaciendaState, RootState>;
type HaciendaActionTree = ActionTree<HaciendaState, RootState>;
export const actions: HaciendaActionTree={
    async cargarDatos(context: HaciendaActionContext){
        console.log('Cargando haciendas')
        try{
            const responde: AxiosResponse=await mainAxios({
                url:'Hacienda/ObtenerSelectItemHacienda'
            });
            const payload:any=responde && responde.data;
            //@ts-ignore
            if(payload.isSuccess){
                //@ts-ignore
                context.commit('setHaciendas', payload.isResult);
                mensajes.exito('Haciendas cargadas exitosamente');
            }else{
                Vue.notify({
                    group: 'foo',
                    title: 'ERROR',
                    text: 'Error al cargar las haciendas: '+payload.isMessage,
                    type:'error'
                });
            }
        } catch(e){
            mensajes.error('Error al cargar las haciendas: No se pudo establecer una conexión con el servidor');
        }
    }
}