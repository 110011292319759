import { MaestroKtb } from "./types";

export const state: MaestroKtb={
    espesor: [],
    largo:[],
    defecto:[],
    empleados :[],
    formato:[],
    linea:[],
    material:[],
    mesa:[],
    origen:[],
    tipoMadera:[],
    turno:[],
    haciendas:[],
    anchos :[],
    largoOpcions:[]
}