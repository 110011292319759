import { GetterTree } from "vuex";
import { RootState } from "../types";
import SistemaParte from './types';

type TodoGetter = GetterTree<SistemaParte,RootState>;

export const getters: TodoGetter = {
    getThemeMode(state:SistemaParte)
    {
        return state.themeMode;
    },

    getVuetifyPreset(state:SistemaParte)
    {
        return state.vuetifyPresent;
    }
}