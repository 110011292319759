import { UsuarioState } from "./types";
import { RootState } from "../types";
import { ActionContext,ActionTree } from "vuex";
import {mainAxios} from '@/plugins/axios';
type UsuarioActionContext =ActionContext<UsuarioState,RootState>;
type UsuarioActionTree = ActionTree<UsuarioState,RootState>;

export const actions: UsuarioActionTree=
{
    async loginUser(context:UsuarioActionContext,payload)
    {
        try
        {
            let {data} = await mainAxios({
               method : 'POST',
               url : 'auth/login',
               data : payload
            });
            if(data.isSuccess)
            {   var result = data.isResult;
                var {sistema} = data.isResult;
                context.commit('usuarioLogin',data.isResult);

                if(result.sistemaId == 1)
                {
                    context.commit('maestroForestal/setLabor', sistema.labor,{root:true});
                    context.commit('maestroForestal/setHacienda', sistema.haciendas,{root:true});
                    context.commit('maestroForestal/setTipoCuadrilla', sistema.tipoCuadrilla,{root:true});
                    context.commit('maestroForestal/setCuadrilla', sistema.cuadrillas,{root:true});
                    context.commit('maestroForestal/setContratista', sistema.contratistas,{root:true});
                    context.commit('maestroForestal/setTipoLabor',sistema.tipoLabor,{root:true});
                    context.commit('maestroForestal/setEmpleado',sistema.empleados,{root:true});
                }
                //KTB
                else if(result.sistemaId == 2)
                {
                    context.commit('maestroKtb/setEspesor',sistema.espesor,{root:true});
                    context.commit('maestroKtb/setLargo',sistema.largo,{root:true});
                    context.commit('maestroKtb/setDefecto',sistema.defecto,{root:true});
                    context.commit('maestroKtb/setTipoMadera',sistema.tipoMadera,{root:true});
                    context.commit('maestroKtb/setOrigen',sistema.origen,{root:true});
                    context.commit('maestroKtb/setFormato',sistema.formato,{root:true});
                    context.commit('maestroKtb/setEmpleado',sistema.empleados,{root:true});
                    context.commit('maestroKtb/setLinea',sistema.linea,{root:true});
                    context.commit('maestroKtb/setMaterial',sistema.material,{root:true});
                    context.commit('maestroKtb/setMesa',sistema.mesa,{root:true});
                    context.commit('maestroKtb/setTurno',sistema.turno,{root:true});
                    context.commit('maestroKtb/setHacienda',sistema.haciendas,{root:true});
                    context.commit('maestroKtb/setAncho',sistema.anchos,{root:true});
                    context.commit('maestroKtb/setLargoOpcion',sistema.largoOpcions,{root:true});

                }
                //Obtenemos la informacion
                else if(result.sistemaId == 3)
                {
                    context.commit('maestroVerde/setEspesor',sistema.espesor,{root:true});
                    context.commit('maestroVerde/setLargo',sistema.largo,{root:true});
                    context.commit('maestroVerde/setDefecto',sistema.defecto,{root:true});
                }
                return true;
            }
            else
            {
                context.commit('mostrarError',data.isMessage);
                return false;
            }
        }
        catch(error)
        {

            context.commit('mostrarError',error);
            return false;
        }
    }
}
