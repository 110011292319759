import {Module} from 'vuex';
import {state} from './state';
//import {actions} from './actions'
import {mutations} from './mutations';
import {actions} from './actions';
import {Labores} from './types';
import {RootState} from '@/store/types';
const namespaced: boolean=true;

export const laboresForestal:Module<Labores,RootState>={
    namespaced,
    state,
    mutations,
    actions
    
}