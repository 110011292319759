import { MaestroKtb} from './types'
import {MutationTree} from 'vuex';
import { Espesor } from '@/entidades/Maestro/Ktb/Espesor';
import { Largo } from '@/entidades/Maestro/Ktb/Largo';
import { Defecto } from '@/entidades/Maestro/Ktb/Defecto';
import { TipoMadera } from '@/entidades/Maestro/Ktb/TipoMadera';
import { Origen } from '@/entidades/Maestro/Ktb/Origen';
import { Formato } from '@/entidades/Maestro/Ktb/Formato';
import Empleado from '@/entidades/Plantacion/Empleado';
import { Linea } from '@/entidades/Maestro/Ktb/Linea';
import { Material } from '@/entidades/Maestro/Ktb/Material';
import { Mesa } from '@/entidades/Maestro/Ktb/Mesa';
import { TurnoQuery } from '@/entidades/KTB/RegistroInformacion/Turno';
import HaciendaItem from '@/entidades/Plantacion/HaciendaItem';
import AnchoResponse from '@/entidades/Maestro/Ktb/Ancho';
import { LargoOpcionModel } from '@/entidades/Maestro/Ktb/LargoOpcions';
type MaestroMutation = MutationTree<MaestroKtb>;


export const mutations: MaestroMutation={
    setEspesor(state:MaestroKtb,payload:Array<Espesor>)
    {
        state.espesor  = payload;
    },
    setLargo(state:MaestroKtb,payload:Array<Largo>)
    {
        state.largo  = payload;
    },
    setDefecto(state:MaestroKtb,payload:Array<Defecto>)
    {
        state.defecto  = payload;
    },
    setTipoMadera(state:MaestroKtb,payload:Array<TipoMadera>)
    {
        state.tipoMadera  = payload;
    },
    setOrigen(state:MaestroKtb,payload:Array<Origen>)
    {
        state.origen  = payload;
    },
    setFormato(state:MaestroKtb,payload:Array<Formato>)
    {
        state.formato  = payload;
    },
    setEmpleado(state:MaestroKtb,payload:Array<Empleado>)
    {
        state.empleados  = payload;
    },
    setLinea(state:MaestroKtb,payload:Array<Linea>)
    {
        state.linea  = payload;
    },
    setMaterial(state:MaestroKtb,payload:Array<Material>)
    {
        state.material  = payload;
    },
    setMesa(state:MaestroKtb,payload:Array<Mesa>)
    {
        state.mesa  = payload;
    },
    setTurno(state:MaestroKtb,payload:Array<TurnoQuery>)
    {
        state.turno  = payload;
    },
    setHacienda(state:MaestroKtb,payload:Array<HaciendaItem>)
    {
        state.haciendas = payload;
    },
    setAncho(state:MaestroKtb,payload:Array<AnchoResponse>)
    {
        state.anchos = payload;
    },
    setLargoOpcion(state:MaestroKtb,payload:Array<LargoOpcionModel>)
    {
        state.largoOpcions = payload;
    }
}