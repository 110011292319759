import { Module } from "vuex";
import {state} from './state';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import SistemaParte from './types';
import { RootState } from "../types";


const namespaced: boolean = true;

export const sistemaModule:Module<SistemaParte,RootState>={
    namespaced,
    state,
    getters,
    actions,
    mutations
}