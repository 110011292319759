












































































































































import { NotificacionDisponible,ObtenerNotificacion } from '@/entidades/Plantacion/Partes/NotificacionDisponible';
import { Modulo } from '@/store/Auth/types';
import {Vue,Component} from 'vue-property-decorator'
import {State,Getter,Mutation,Action} from 'vuex-class';
import Item from './Item.vue';
import NotificacionDrawer from './NotificacionLeft.vue';
import MenuVertical from './MenuVertical.vue';
import Account from './Account.vue';
let namespace  ='authModule';
let namespaceParte  = 'parteModule';
const namespaceModulo:string ='sistemaModule';
@Component({
    components:{
        Item,
        NotificacionDrawer,
        Account,
        MenuVertical,
        'changePassword' : () => import('../Security/ChangePassword.vue')
    }
})
export default class Menu extends Vue
{
    nameSistem:string = 'Administracion';
    
    @State('userAuth',{namespace}) userAuth!:boolean;
    @State('fullName',{namespace}) fullName!:string;
    @State('usuarioId',{namespace}) usuarioId!:string;
    @State('notificacion',{namespace}) notificacion!:number;
    @State('modulos',{namespace}) modulos!:Array<Modulo>;
    @Getter('getThemeMode',{namespace:namespaceModulo}) getThemeMode!:any;

    @Mutation('limpiarParte',{namespace: namespaceParte})limpiarParte!:Function;;
    @Mutation('usuarioLogout',{namespace})usuarioLogout!:Function;;
    @Action('changeVerticalSidebarDrawer',{namespace:'sistemaModule'}) changeVerticalSidebarDrawer!:Function
    @Action('changeVerticalSidebarMini',{namespace:'sistemaModule'}) changeVerticalSidebarMini!:Function

    drawer:boolean=false;
    miniVariante:boolean=false;
    background:boolean= false;
    notificationDrawer:boolean=false;
    perfilDrawer:boolean=false;
    notificacionComponent:string="";
    perfilComponent:string="";
    listadoNotifiacion:Array<NotificacionDisponible> =[];
    modal:boolean = false;
    nameComponent:string="";
    irRuta(item:any)
    {
        console.log(item);
    }

    async ObtenerNotificacion()
    {
      try
      {
        let response = await ObtenerNotificacion(this.usuarioId);
        if(response.isSuccess == true)
        {
          this.listadoNotifiacion = response.isResult;
          this.notificacionComponent = "NotificacionDrawer";
          this.notificationDrawer = true;
        }
      }
      catch(error)
      {
        console.log(error);
      }
    }

    changePasswordOpen()
    {
      this.modal = true;
      this.nameComponent = 'changePassword'
    }
    closeDrawer()
    {
      this.notificationDrawer  = false;
      this.notificacionComponent ="";
    

    }
    closeModal()
    {
      this.modal = false;
      this.nameComponent = "";
    }

    mostrarPerfil()
    {
      this.perfilComponent = "Account";
      this.notificationDrawer = true;
    }

    logout()
    {
      this.usuarioLogout();
      this.limpiarParte();
      this.$router.replace('/login');
    }

   toggleVerticalSidebarDrawer() {
      this.changeVerticalSidebarDrawer();

      // this.$emit("update:mini-variant");
      // console.log("check");
    }
    navbarOptions: any = {
        elementId: "main-navbar",
        isUsingVueRouter: true,
        mobileBreakpoint: 992,
        brandImagePath: "./",
        brandImageAltText: "brand-image",
        collapseButtonOpenColor: "#661c23",
        collapseButtonCloseColor: "#661c23",
        showBrandImageInMobilePopup: true,
        ariaLabelMainNav: "Main Navigation",
        tooltipAnimationType: "shift-away"
      }
      
    get property()
    {
      if(this.nameComponent = 'changePassword')
      {
        return {'usuarioId' : this.usuarioId};
      }
    }
}
