






















































































































import { Getter, Action, State } from 'vuex-class';
//@ts-ignore
import vueperfectscrollbar from 'vue-perfect-scrollbar';
import {Vue,Component} from 'vue-property-decorator';
import { ThemeConfig } from '@/temas';
import { Modulo } from '@/store/Auth/types';

@Component({
    components:{
        vueperfectscrollbar,
        'base-item' : () => import('@/components/Base/Item.vue'),
        'base-item-group' :() => import('@/components/Base/ItemGroup.vue')
    }
})
export default class MenuVerticalComponent extends Vue
{
      drawer:boolean= true;
      switch1:string= "on";
      color:string= "dark";
      colors:Array<string> =  ["primary", "blue", "success", "red", "teal"];
      right:boolean= false;
      permanent:boolean= true;
      miniVariant: boolean = false;
      expandOnHover:boolean= false;
      background:boolean= false;
      opacity: number= 0.4

      @State('sistemaName',{namespace:'authModule'}) sistemaName!:string;
      @State('sistemaId',{namespace:'authModule'}) sistemaId!:number;
      @State('fullName',{namespace:'authModule'}) fullName!:string;
      @State('modulos',{namespace:'authModule'})modulos!:Array<Modulo>
      @Getter('getThemeMode',{namespace:'sistemaModule'}) getThemeMode!:ThemeConfig;
      @Action('changeVerticalSidebarDrawer',{namespace:'sistemaModule'}) changeVerticalSidebarDrawer!:Function;
      @Action('changeVerticalSidebarMini',{namespace:'sistemaModule'}) changeVerticalSidebarMini!:Function;

    verPerfil()
    {
      if(this.sistemaId == 1)
      {
        this.$router.push({name : 'HomePrincipalForestal'})
      }

    }


   get bg() {
      return this.background
        ? "https://images.pexels.com/photos/1687678/pexels-photo-1687678.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
        : undefined;
    }
    get getMiniVariant() {
      return this.miniVariant;
    }
    get computedItems() {
      return this.modulos.map(this.mapItem);
    }
    toggleSidebar() {
      this.changeVerticalSidebarMini();
      this.expandOnHover = !this.expandOnHover;
      // this.$emit("update:mini-variant");
      // console.log("check");
    }
    mapItem(item:any) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title
      };
    }
};
