import SistemaState from './types';
import { MutationTree } from 'vuex';
import Loading from '@/entidades/Sistema/Loading';
import Alerta from '@/entidades/Sistema/Alerta';
import { sameAs } from 'vuelidate/lib/validators';


type SistemaMutationTree = MutationTree<SistemaState>;

export const mutations:SistemaMutationTree={
    toggleThemeDarkMode(state, data) {
        state.themeMode.isDark = data;
        //this.$vuetify.theme.dark = data;
      },
      toggleThemeLayout(state, data) {
        state.themeMode.layout = data;
      },
      toggleThemeRtl(state) {
        state.themeMode.rtl = !state.themeMode.rtl;
      },
      toggleThemeLoadingState(state, payload) {
        state.themeMode.isLoading = payload;
      },
      toggleVerticalSidebarDrawer(state) {
        state.themeMode.verticalSidebarDrawer = !state.themeMode.verticalSidebarDrawer;
      },
      toggleVerticalSaasSidebarDrawer(state) {
        state.themeMode.verticalSaasSidebarDrawer = !state.themeMode
          .verticalSaasSidebarDrawer;
      },
      toggleVerticalSidebarDrawerColor(state, data) {
        state.themeMode.verticalSidebarDrawerColor = data;
      },
      toggleAppBarColor(state, data) {
        state.themeMode.appBarColor = data;
      },
      toggleCompactVerticalSidebarDrawer(state) {
        state.themeMode.verticalCompactSidebarDrawer = !state.themeMode
          .verticalCompactSidebarDrawer;
      },
      toggleVerticalSidebarMini(state) {
        state.themeMode.verticalSidebarMini = !state.themeMode.verticalSidebarMini;
      },

      mostrarLoading(state,payload:Loading)
      {
          state.loading = payload.mostrar == undefined ? false:payload.mostrar;
          state.loadingText = payload.mensaje == undefined ? "" : payload.mensaje;
      },

      mostarAlert(state,payload:Alerta)
      {
         state.alert = payload;
      }

}