import { despachoApi } from '@/entidades/Recepcion/Despacho';
import {MutationTree} from 'vuex';
import {DespachoModel, DespachoState} from './types'
import Vue from 'vue'
import { mensajes } from '@/plugins/notify';
type DespachosMutationTree = MutationTree<DespachoState>;
export const mutations: DespachosMutationTree={
    setDespachos(state: DespachoState, payload: DespachoModel[]){
        state.despachos=payload;
    },
    addDespacho(state: DespachoState, payload: DespachoModel){
        var Desp=state.despachos.find(e=>e.numeroDespacho==payload.numeroDespacho);
        if(Desp){
            console.log('Despacho existente')
        }else{
            state.despachos.push(payload);
        }
    },
    editDespacho(state: DespachoState, payload: DespachoModel){
        console.log('Llegamos a la edicion mutation')
        var Desp=state.despachos.find(e=>e.numeroDespacho==payload.numeroDespacho);
        if(Desp){
            var indice=state.despachos.indexOf(Desp);
            if(indice>=0){
                console.log("Encontrado indice: ", indice)
                state.despachos[indice].distribucion=payload.distribucion;
            }else{
                console.log('Despacho no encontrado con indexOf')
            }
        }else{
            console.log('Despacho no encontrado con find')
        }
    },
    //Con el botón eliminar
    deleteDespacho(state: DespachoState, payload: number){
        if(payload>=0){
            state.despachos.splice(payload,1)
            console.log('Eliminado')
            Vue.notify({
                group: 'foo',
                title: 'Realizado',
                text: 'Despacho eliminado',
                type:'success'
            });
            mensajes.exito('Despacho eliminado');
        }else{
            mensajes.error('No se pudo encontrar el despacho');
        }
    },
    //Despues de enviar los datos
    deleteDespacho2(state: DespachoState, payload: despachoApi){
        var tmp=state.despachos.find(e=>e.numeroDespacho==payload.numeroDespacho)
        if(tmp){
            var indice=state.despachos.indexOf(tmp);
            if(indice>=0){
                state.despachos.splice(indice,1)
                console.log('Eliminado')
            }else{
                console.log('No encontrado con el indice')
            }
        }else{
            console.log('Despacho no encontrado con find')
        }
    }
}