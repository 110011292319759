import { VerdeState} from './types'
import {MutationTree} from 'vuex';
import { DespachoKTBVerde } from "@/entidades/MaderaVerde/KtbDespacho";
type VerdeMutationTree = MutationTree<VerdeState>;


export const mutations: VerdeMutationTree={

    setDespacho(state:VerdeState,payload:Array<DespachoKTBVerde>)
    {
        state.despachoVerde = payload;
    },

    setDespachoCalificar(state:VerdeState,payload:DespachoKTBVerde)
    {
        var despachoOriginal = state.despachoVerde.find(c=>c.despachoId == payload.despachoId);
        var rsp = state.despachoCalificado.indexOf(payload);
        if(rsp < 0)
        {
            if(despachoOriginal != undefined)
            {
                var index = state.despachoVerde.indexOf(despachoOriginal);
                console.log(index);
                state.despachoVerde.splice(index,1);
            }
            state.despachoCalificado.push(payload);
        }
    },

    setDespachoDelete(state:VerdeState,payload:number)
    {
        var despachoOriginal = state.despachoVerde.find(c=>c.despachoId == payload);
        var index = state.despachoVerde.indexOf(despachoOriginal!);
        state.despachoVerde.splice(index,1);
    },
    setDespachoCalificarLimpiar(state:VerdeState)
    {
        state.despachoCalificado = [];
    },
    setDespachoCalificarItem(state:VerdeState,payload:number)
    {
        var despacho = state.despachoCalificado.find(c=>c.despachoId == payload);
        var index = state.despachoCalificado.indexOf(despacho!);
        state.despachoCalificado.splice(index,1);

        
    }

}