import {PlantaModel, PlantaState} from './types'
import {ActionContext, ActionTree} from 'vuex';
import {RootState} from '@/store/types'
import {AxiosResponse} from 'axios'
import { mainAxios } from '@/plugins/axios';
import { mensajes } from '@/plugins/notify';

type PlantaActionContext=ActionContext<PlantaState,RootState>;
type PlantaActionTree = ActionTree<PlantaState,RootState>;
export const actions: PlantaActionTree={
    async cargarPlantas(context: PlantaActionContext){
        console.log('Cargando Plantas')
        try{
            const responde: AxiosResponse=await mainAxios({
                url:'planta/obtener'
            });
            const payload:any=responde && responde.data;
            //@ts-ignore
            if(payload.isSuccess){
                //@ts-ignore
                mensajes.exito('Plantas cargadas correctamente');
                context.commit('setPlantas', payload.isResult);
            }else{
                mensajes.error('Error al cargar las plantas: '+payload.isMessage)
            }
        } catch(e){
            mensajes.error('Error al cargar las plantas: No se pudo establecer una conexión con el servidor')         
        }
    }
}