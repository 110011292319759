import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'
import {themeConfig,themePreset} from '@/themeConfig';

Vue.use(Vuetify);

/*
ICONOS:
    https://fontawesome.com/v4/icons/
*/
export default new Vuetify({
    icons : {
        iconfont : 'fa'
        
    },
    rtl:false,
    theme: {
        themes: {
            light: {
                primary: colors.green.lighten1,
                success: colors.blue.darken1,
                secondary: colors.grey.darken1,
                action: colors.lightGreen.lighten2,
                edit: colors.orange.darken2,
                accent: colors.shades.black,
                error: colors.red.lighten2,
                cancel: colors.blueGrey.lighten1,
                danger: colors.red.lighten1
            },
            dark:
            {
                background: "#f2f3f8"
            }
         },
         options :{
             customProperties: true
         }
    }
});
