


















import Alerta from '@/entidades/Sistema/Alerta';
import Vue from 'vue'
import {Component} from 'vue-property-decorator';
import {State} from 'vuex-class';
@Component({
    name : 'MensajeAlert'
})
export default class MensajeAlert extends Vue
{
 
    @State('alert',{namespace:'sistemaModule'}) alert!:Alerta;


}
