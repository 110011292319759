


























import {Component, Vue} from 'vue-property-decorator';
import { State } from 'vuex-class';


@Component
export default class LoadingComponent extends Vue 
{
    @State('loading',{namespace :'sistemaModule'}) loading!:boolean;
    @State('loadingText',{namespace :'sistemaModule'}) loadingText!:boolean;

}
