import { ItemParteModel } from '@/entidades/Plantacion/Partes/ItemParteModel';
import { LaborModel } from '@/entidades/Plantacion/Partes/LaborModel';
import { ParteApiModel } from '@/entidades/Plantacion/Partes/ParteApiModel';
import { HoraEmpleado, ParteDetalleModel, ParteModel } from '@/entidades/Plantacion/Partes/ParteModel';
import { TipoCuadrilla } from '@/entidades/Plantacion/Cuadrilla';
import Hacienda from '@/entidades/Plantacion/Hacienda';
import TipoLabor from '@/entidades/Plantacion/TipoLabor';
import { mensajes } from '@/plugins/notify';
import {MutationTree} from 'vuex';
import { IParteCabeceraAprobacion, ParteState } from './types';

type ParteMutationTree=MutationTree<ParteState>;

export const mutations:ParteMutationTree=
{
    setPartes(state: ParteState, payload: ItemParteModel[]){
        state.partes=payload;
    },
    setLabores(state: ParteState, payload:LaborModel[]){
        state.labores=payload;
    },

    setTipoLabor(state,payload:TipoLabor[])
    {
        state.tipoLabor = payload;
    },

    setPartesPendientes(state: ParteState, payload:IParteCabeceraAprobacion[]){
        state.partesPendientesDeAprobacion=payload;
    },
    deletePartePendiente(state: ParteState, id:number){
        var tmp =state.partesPendientesDeAprobacion.find(e=>e.parteId==id);
        if(tmp){
            var indice=state.partesPendientesDeAprobacion.indexOf(tmp);
            if(indice>=0){
                state.partesPendientesDeAprobacion.splice(indice,1);
            }else{
                console.log('Parte no encontrado!');
            }
        }else{
            console.log('Parte no encontrado!');
        }
    },
    setPartesEnviados(state: ParteState, payload:ParteApiModel[]){
        state.partesEnviados=payload;
    },

    limpiarParte(state:ParteState)
    {
        state.contratistas  =[];
        state.cuadrillas = [];
        state.labores =[];
        state.partes =[];
        state.partesEnviados =[];
        state.partesPendientesDeAprobacion= [];
        state.tipoLabor =[];   
    },

     //Cambios de Steven

    setHacienda(state:ParteState,payload:Hacienda[])
    {
        state.haciendas = payload;
    },
    setTipoCuadrilla(state:ParteState,payload:TipoCuadrilla[])
    {
        state.tipoCuadrilla = payload;
    },
    agregarParteLocal(state:ParteState,payload:ParteModel)
    {
        state.partesLocales.push(payload);
    },

    removerParteLocal(state:ParteState,id:number)
    {
        let parte = state.partesLocales.find(c=>c.parteId == id);
        //let index = state.partesLocales.indexOf(parte);
        if(parte != undefined)
        {   let index = state.partesLocales.indexOf(parte)
            state.partesLocales.splice(index,1);
        }
    },
    agregarDetalleParte(state:ParteState,payload:ParteDetalleModel)
    {
        let index = 0;
        let parte = state.partesLocales.find(c=>c.parteId == payload.parteId);
        if(parte != undefined)
        {
            index = state.partesLocales.indexOf(parte);
            if(state.partesLocales[index].items == null)
                state.partesLocales[index].items = new Array<ParteDetalleModel>();

            state.partesLocales[index].items?.push(payload);
            let inicio:number=0;
            let suma = state.partesLocales[index].items?.reduce(function(total,currentValue){
                return total + currentValue.cantidad
            },inicio)

            state.partesLocales[index].cantidadTotal = suma;
        }
        
    },

    removerDetalleParte(state:ParteState,payload:ParteDetalleModel)
    {
        let index = 0;
        let parte = state.partesLocales.find(c=>c.parteId == payload.parteId);
        if(parte != undefined)
        {
            index = state.partesLocales.indexOf(parte);
                
            let indexD = state.partesLocales[index].items?.indexOf(payload);
            if(indexD != undefined)
            {
                state.partesLocales[index].items?.splice(index,1);
            }
        }
        
    },
    disminuirStockLabor(state:ParteState,payload: ParteDetalleModel)
    {
        let index = 0;
        let parteFind = state.labores.find(c=>c.cicloId == payload.cicloId && c.laborId == payload.laborId && c.loteId == payload.loteId
            && c.haciendaId == payload.haciendaId);
        if(parteFind !=null)
        {
            index = state.labores.indexOf(parteFind);
            state.labores[index].cantidad -= payload.cantidad;
            state.labores[index].cantidadPlantula -= payload.cantidadPlantula;
        }
    },

    aumentarStockLabor(state:ParteState,payload: ParteDetalleModel)
    {
        let index = 0;
        let parteFind = state.labores.find(c=>c.cicloId == payload.cicloId && c.laborId == payload.laborId && c.loteId == payload.loteId
            && c.haciendaId == payload.haciendaId);
        if(parteFind !=null)
        {
            index = state.labores.indexOf(parteFind);
            state.labores[index].cantidad += payload.cantidad;
            state.labores[index].cantidadPlantula += payload.cantidadPlantula;
        }
    },

    agregarHoraDetalle(state:ParteState,payload:Array<HoraEmpleado>)
    {
        let indexParte = 0
        let indexDetalle:number |undefined = 0;
        let objeto = payload[0];
        let parte = state.partesLocales.find(c=>c.parteId == objeto.parteId);
        console.log('parte desde agregar horas',parte)
        if(parte != undefined)
        {
            indexParte = state.partesLocales.indexOf(parte);
            let item = parte.items?.find(c=>c.detalleId == objeto.detalleId);
            console.log('el detalle desde agregar horas',item);
            
        }

    }

    
}