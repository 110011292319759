import { MaestroVerde} from './types'
import {MutationTree} from 'vuex';
import { Espesor } from '@/entidades/Maestro/Ktb/Espesor';
import { Largo } from '@/entidades/Maestro/Ktb/Largo';
import { Defecto } from '@/entidades/Maestro/Ktb/Defecto';
import { TipoMadera } from '@/entidades/Maestro/Ktb/TipoMadera';
type MaestroMutation = MutationTree<MaestroVerde>;


export const mutations: MaestroMutation={
    setEspesor(state:MaestroVerde,payload:Array<Espesor>)
    {
        state.espesor  = payload;
    },
    setLargo(state:MaestroVerde,payload:Array<Largo>)
    {
        state.largo  = payload;
    },
    setDefecto(state:MaestroVerde,payload:Array<Defecto>)
    {
        state.defecto  = payload;
    },
    setTipoMadera(state:MaestroVerde,payload:Array<TipoMadera>)
    {
        state.tipoMadera  = payload;
    },




}