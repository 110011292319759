import {ActionContext, ActionTree} from 'vuex';
import {RootState} from '@/store/types'
import {AxiosResponse} from 'axios'
import { mainAxios, secondAxios } from '@/plugins/axios';
import { Labores } from './types';
import { ParteApiModel } from '@/entidades/Plantacion/Partes/ParteApiModel';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import { mensajes } from '@/plugins/notify';
import { ParteDetalleModel, ParteModel } from '@/entidades/Plantacion/Partes/ParteModel';
import ResponseCustom from '@/entidades/ResponseCustom';

let randon = Math.floor((Math.random() *(100-1+1))+1);
type ParteActionContext=ActionContext<Labores,RootState>;
type ParteActionTree=ActionTree<Labores,RootState>;

export const actions: ParteActionTree={
   

    agregarParteLocal(context:ParteActionContext,parte:ParteModel):ResponseGenerico
    {
        let parteLocal:ParteModel | undefined = undefined;
        let response:ResponseGenerico = new ResponseGenerico(false,"");
        if(parte.tipoCuadrilla == 1)
        {
             parteLocal = context.state.partesLocales.find(c=>c.haciendaId == parte.haciendaId);
        }
        else
        {
            parteLocal = context.state.partesLocales.find(c=>c.haciendaId == parte.haciendaId && c.contratistaId == parte.contratistaId);
        }


        if(parteLocal == undefined)
        {
            
            parte.parteId = Math.floor((Math.random() *(100-1+1))+1);
            context.commit('agregarParteLocal',parte);
            response = new ResponseGenerico(true,"Parte Agregado");
            
        }
        else
        {
            response = new ResponseGenerico(false,"Hacienda ya se encuentra agregada");
        }
        return response;   
    },

    agregarParteDetalle(context:ParteActionContext,parteDetalle:ParteDetalleModel):ResponseGenerico
    {
        let response:ResponseGenerico = new ResponseGenerico(false,"");
        
         parteDetalle.detalleId = Math.floor((Math.random() *(100-1+1))+1);
         try
         {
            context.commit('agregarDetalleParte',parteDetalle);
            response = new ResponseGenerico(true,"Detalle Agregado");
         }
         catch(error)
         {
             console.log(error);
             //@ts-ignore
            response = new ResponseGenerico(false,error);
         }

        return response;
    },
    
}