


























import {Vue,Component,Prop} from 'vue-property-decorator';
@Component({
    name : 'ConfirmacionDialog'
})
export default class ConfirmacionDialog extends Vue
{
 
    @Prop({type:Boolean,required:true,default:false}) open!:boolean;
    @Prop({type:String,required:true,default:'Esta seguro de la accion a realizar?'}) message!:string;
    @Prop({type:Function,required:true}) methodDialog!:Function;
    @Prop({type:Object,required:true,default:false}) objeto!:any;
    @Prop({type:Number,required:true,default:1}) tipo!:number;

    motivo:string="";

    yes()
    {
        //Validamos que ingrese un motivo valido
        this.methodDialog(true,this.objeto,this.motivo,this.tipo);
    }

    no()
    {
        this.methodDialog(false,this.objeto,this.motivo,this.tipo);
    }



    get abrirModal()
    {
        return this.open;
    }

    get validarMessageTipo()
    {
        if(this.tipo == 2)
        {
            if(this.motivo === "")
            {
                return true;
            }
        }
        return false;
    }

}
