import {Module} from 'vuex';
import {state} from './state';
//import {actions} from './actions'
import {mutations} from './mutations';
import {MaestroForestal} from './types';
import {RootState} from '@/store/types';
const namespaced: boolean=true;

export const maestroForestal:Module<MaestroForestal,RootState>={
    namespaced,
    state,
    mutations
    
}