export default class ResponseCustom<T>
{
    public isSuccess:boolean;
    public isMessage:string;
    public isResult:Array<T>;

    constructor(_isSuccess:boolean,_isMessage:string,_isResult:Array<T>)
    {
        this.isSuccess = _isSuccess;
        this.isMessage = _isMessage;
        this.isResult = _isResult;
    }
    

}