







import {Component,Vue} from 'vue-property-decorator';
import {State,Mutation} from 'vuex-class';
import {ObtenerCount} from '@/entidades/Plantacion/Partes/NotificacionDisponible';
var namespaced = "authModule";
/*import firebase from 'firebase';
import 'firebase/app';
import 'firebase/messaging';
*/
@Component
export default class App extends Vue
{
 
  @State('userAuth',{namespace:'authModule'}) userAuth!:boolean;
  @State('usuarioId',{namespace:'authModule'}) usuarioId!:string;
  @Mutation('actualizarNotificacion',{namespace:'authModule'}) actualizarNotificacion!:Function;
  async CustomerNotificacion(payload:any)
   {
     if(this.userAuth == true)
     {
       try
       {
          let number = await ObtenerCount(this.usuarioId);
          this.actualizarNotificacion(number);
          //alert(payload.notification.body) 
       }
       catch(error)
       {
         console.log(error);
       }
      
     }
        
   }
   
  mounted() {
   /* if(!firebase.apps.length)
    {
       firebase.initializeApp({
      apiKey: "AIzaSyB1yMCqs-pd8REOGffLPjrD1gPz5RtkdOY",
      authDomain: "forestalpush.firebaseapp.com",
      projectId: "forestalpush",
      storageBucket: "forestalpush.appspot.com",
      messagingSenderId: "921992611089",
      appId: "1:921992611089:web:d9d2aa8e85f6acdc7038b0",
      measurementId: "G-T79QM1G883"
      });
    }
    else
    {
      firebase.app();
    }
   
   const messaing = firebase.messaging();

    messaing.onMessage((payload) =>{
      this.CustomerNotificacion(payload);
    })*/
  }
  
    get theme()
    {
       return this.$vuetify.theme.dark ? 'dark' : 'light'
    }

    

  created() {
   //  this.$vuetify.theme.themes.light.
   
  }
  
}
