import {Module} from 'vuex';
import {state} from './state';
//import {actions} from './actions'
import {mutations} from './mutations';
import {MaestroVerde} from './types';
import {RootState} from '@/store/types';
const namespaced: boolean=true;

export const maestroVerde:Module<MaestroVerde,RootState>={
    namespaced,
    state,
    mutations
    
}