import {ActionContext, ActionTree} from 'vuex';
import {RootState} from '@/store/types'
import {AxiosResponse} from 'axios'
import { mainAxios, secondAxios } from '@/plugins/axios';
import { ParteState } from './types';
import { ParteApiModel } from '@/entidades/Plantacion/Partes/ParteApiModel';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import { mensajes } from '@/plugins/notify';
import { ParteDetalleModel, ParteModel } from '@/entidades/Plantacion/Partes/ParteModel';
import ResponseCustom from '@/entidades/ResponseCustom';

let randon = Math.floor((Math.random() *(100-1+1))+1);
type ParteActionContext=ActionContext<ParteState,RootState>;
type ParteActionTree=ActionTree<ParteState,RootState>;

export const actions: ParteActionTree={
    async enviar(context: ParteActionContext, payload:ParteApiModel){
        console.log('Enviando Parte')
        try{
            const responde: AxiosResponse=await secondAxios({
                url:'Parte/Guardar',
                method:'POST',
                data: payload
            });
            const payload2:any=responde && responde.data;
            //@ts-ignore
            if(payload2.isSuccess){
                //@ts-ignore
                mensajes.exito('Parte enviado exitosamente');
                context.commit('deleteParte2',payload)
                return true;
            }else{
                //
                mensajes.error('Error al enviar los datos: '+payload2.isMessage);
                return false;
            }
        } catch(e){
            mensajes.errorDeConexion();
            return false;
        }
    },

    async cargarPartesPendientes(context: ParteActionContext, userId:string){
        console.log('Cargando Partes pendientes')
        try{
            const responde: AxiosResponse=await secondAxios({
                url:`ParteAprobacion/ObtenerAprobacionPendiente/${userId}`,
                method:'get',
            });
            const payload2:ResponseCustom<ParteApiModel>=responde && responde.data;
            //@ts-ignore
            if(payload2.isSuccess){
                //@ts-ignore
                console.log('EXITO')
                context.commit('setPartesPendientes',payload2.isResult)
                return true;
            }else{
                //
                mensajes.error('Error al cargar los partes pendientes: '+payload2.isMessage)
                return false;
            }
        } catch(e){
            mensajes.error('Error al cargar los partes pendientes. No se pudo establecer una conexión con el servidor ')
            return false;
        }
    },

    async enviarAprobacion(context: ParteActionContext, dato:any){
        console.log('Cargando Partes pendientes')
        try{
            const responde: AxiosResponse=await secondAxios({
                url:`ParteAprobacion/AprobarRechazarParte`,
                method:'post',
                data:dato
            });
            const payload2:ResponseCustom<ParteApiModel>=responde && responde.data;
            //@ts-ignore
            if(payload2.isSuccess){
                //@ts-ignore
                mensajes.exito('Parte aprobado exitosamente')
                context.commit('deletePartePendiente',dato.parteId)
                return true;
            }else{
                //
                mensajes.error('Error al obtener los datos '+payload2.isMessage)
                return false;
            }
        } catch(e){
            mensajes.errorDeConexion();
            return false;
        }
    },
    async cargarPartesEnviados(context: ParteActionContext, userId:string){
        console.log('Cargando Partes pendientes')
        try{
            const responde: AxiosResponse=await secondAxios({
                url:`Parte/ObtenerParteMayordomo/${userId}`,
                method:'get',
            });
            const payload2:ResponseCustom<ParteApiModel>=responde && responde.data;
            //@ts-ignore
            if(payload2.isSuccess){
                //@ts-ignore
                console.log('EXITO')
                payload2.isResult.forEach(el=>{
                    var fecha=el.fecha;
                    el.fecha=fecha.split('T')[0];
                })
                context.commit('setPartesEnviados',payload2.isResult)
                return true;
            }else{
                //
                mensajes.error('Error al obtener los datos '+payload2.isMessage);
                console.log()
                return false;
            }
        } catch(e){
            console.log('Llegamos al error ', e)
            mensajes.errorDeConexion();
            return false;
        }
    },
    async anularParte(context: ParteActionContext, datos:any){
        console.log('Anulando parte', datos.dato)
        try{
            const responde: AxiosResponse=await secondAxios({
                url:`Parte/AnularParte`,
                method:'POST',
                data:datos.dato
            });
            const payload2:ResponseCustom<ParteApiModel>=responde && responde.data;
            //@ts-ignore
            if(payload2.isSuccess){
                //@ts-ignore
                console.log('EXITO')
                context.commit('deteleParteByIndex',datos.index);
                mensajes.exito('Parte anulado exitosamente')
                return true;
            }else{
                //
                mensajes.error('Error al realizar la anulacion: '+payload2.isMessage)
                console.log('Error al obtener los datos ',payload2.isMessage)
                return false;
            }
        } catch(e){
            mensajes.errorDeConexion();
            return false;
        }
    },


    agregarParteLocal(context:ParteActionContext,parte:ParteModel):ResponseGenerico
    {
        let parteLocal:ParteModel | undefined = undefined;
        let response:ResponseGenerico = new ResponseGenerico(false,"");
        if(parte.tipoCuadrilla == 1)
        {
             parteLocal = context.state.partesLocales.find(c=>c.haciendaId == parte.haciendaId);
        }
        else
        {
            parteLocal = context.state.partesLocales.find(c=>c.haciendaId == parte.haciendaId && c.contratistaId == parte.contratistaId);
        }


        if(parteLocal == undefined)
        {
            
            parte.parteId = randon;
            context.commit('agregarParteLocal',parte);
            response = new ResponseGenerico(true,"Parte Agregado");
            
        }
        else
        {
            response = new ResponseGenerico(false,"Hacienda ya se encuentra agregada");
        }
        return response;   
    },
    agregarParteDetalle(context:ParteActionContext,parteDetalle:ParteDetalleModel):ResponseGenerico
    {
        let response:ResponseGenerico = new ResponseGenerico(false,"");

         parteDetalle.detalleId = randon;
         try
         {
            context.commit('agregarDetalleParte',parteDetalle);
            context.commit('disminuirStockLabor',parteDetalle);
            response = new ResponseGenerico(true,"Detalle Agregado");
         }
         catch(error)
         {
             console.log(error);
             //@ts-ignore
            response = new ResponseGenerico(false,error);
         }

        return response;
    },
    removerParteDetalle(context:ParteActionContext,parteDetalle:ParteDetalleModel):ResponseGenerico
    {
        let response:ResponseGenerico = new ResponseGenerico(false,"");
        try
        {
            context.commit('removerDetalleParte',parteDetalle);
            context.commit('aumentarStockLabor',parteDetalle);
            response = new ResponseGenerico(true,"Detalle Eliminado");
        }
        catch(error)
        {
            console.log(error);
             //@ts-ignore
            response = new ResponseGenerico(false,error);
        }
        return response;
    },

    removeParteLocalAction(context:ParteActionContext,parteId:number)
    {
        let detalles  = context.state.partesLocales.find(c=>c.parteId == parteId)?.items;
        if(detalles !=undefined)
        {
            detalles.forEach(c=> {
                context.commit('aumentarStockLabor',c);
                context.commit('removerDetalleParte',c);
            })
            
            context.commit('removerParteLocal',parteId);
        }

    }
}