import { Labores} from './types'
import {MutationTree} from 'vuex';
import { CuadrillaHorario } from '@/entidades/Plantacion/CuadrillaHorario';
import { ParteDetalleModel, ParteModel } from '@/entidades/Plantacion/Partes/ParteModel';
type LaboresMutation = MutationTree<Labores>;


export const mutations: LaboresMutation={
   setHorarioAdd(state:Labores,payload:CuadrillaHorario)
   {
      var find = state.horarioEmpleados.find(c=>c.cuadrillaId == payload.cuadrillaId && c.fecha == payload.fecha);
      if(find == undefined)
      {
          state.horarioEmpleados.push(payload);
      }
   },
   setHorarioEstado(state:Labores,payload:CuadrillaHorario)
   {
    var horario = state.horarioEmpleados.find(c=>c.cuadrillaId == payload.cuadrillaId && c.fecha == payload.fecha);
    if(horario != undefined)
    {
      var index = state.horarioEmpleados.indexOf(horario);
      state.horarioEmpleados[index].estadoProceso = "REGISTRADO BASE";
      state.horarioEmpleados[index].registrado = true;
    }
   
   },
   setHorarioDelete(state:Labores)
   {
    state.horarioEmpleados = [];
   },
   setHorarioDeleteItem(state:Labores,payload:CuadrillaHorario)
   {
        var indexOf = state.horarioEmpleados.indexOf(payload);
        state.horarioEmpleados.splice(indexOf,1);   
   },

   //Todo Relacionado a la crecion del parte
   agregarParteLocal(state:Labores,payload:ParteModel)
    {
        state.partesLocales.push(payload);
    },
    agregarDetalleParte(state:Labores,payload:ParteDetalleModel)
    {
        let index = 0;
        let parte = state.partesLocales.find(c=>c.parteId == payload.parteId);
        if(parte != undefined)
        {
            index = state.partesLocales.indexOf(parte);
            if(state.partesLocales[index].items == null)
                state.partesLocales[index].items = new Array<ParteDetalleModel>();

            state.partesLocales[index].items?.push(payload);
            
            /*let inicio:number=0;
            let suma = state.partesLocales[index].items?.reduce(function(total,currentValue){
                return total + currentValue.cantidad
            },inicio)

            state.partesLocales[index].cantidadTotal = suma;*/
        }
        
    },

    actualizarDetalle(state:Labores,payload:ParteDetalleModel)
    {
        
        var parte = state.partesLocales.find(c=>c.parteId == payload.parteId)
        var index = state.partesLocales.indexOf(parte!);
        var detalle = parte?.items.find(t=>t.detalleId == payload.detalleId);
        var indexDetalle = state.partesLocales[index].items.indexOf(detalle!);
        state.partesLocales[index].items[indexDetalle].imagen = payload.imagen;
        state.partesLocales[index].items[indexDetalle].cantidad = Number(payload.cantidad);
        state.partesLocales[index].items[indexDetalle].cantidadPlantula = Number(payload.cantidadPlantula);
        state.partesLocales[index].items[indexDetalle].cerrada = true;
        

         let inicio:number=0;
            let suma = state.partesLocales[index].items?.reduce(function(total,currentValue){
                return Number(total) + Number(currentValue.cantidad)
            },inicio)

            state.partesLocales[index].cantidadTotal = Number(suma);

    },

    eliminarDetalle(state:Labores,payload:ParteDetalleModel)
    {
        //Primero buscamos el parte
        var parte = state.partesLocales.find(c=>c.parteId == payload.parteId);
        if(parte != undefined)
        {
            var indexParte = state.partesLocales.indexOf(parte);
            var detalle = parte.items.find(c=>c.detalleId == payload.detalleId);
            if(detalle != undefined)
            {
                var indexDetalle = state.partesLocales[indexParte].items.indexOf(detalle);
                state.partesLocales[indexParte].items.splice(indexDetalle,1);
            }
        }
    },
    actualizarParte(state:Labores,payload:ParteDetalleModel)
    {
        var parte = state.partesLocales.find(c=>c.parteId == payload.parteId)
        var index = state.partesLocales.indexOf(parte!);
        
        let inicio:number=0;
            let suma = state.partesLocales[index].items?.reduce(function(total,currentValue){
                return Number(total + currentValue.cantidad)
            },inicio)

            state.partesLocales[index].cantidadTotal = suma;
    },
    removerParteLocal(state:Labores,id:number)
    {
        let parte = state.partesLocales.find(c=>c.parteId == id);
        //let index = state.partesLocales.indexOf(parte);
        if(parte != undefined)
        {   let index = state.partesLocales.indexOf(parte)
            state.partesLocales.splice(index,1);
        }
    },


}