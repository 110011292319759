import {Module} from 'vuex';
import {state} from './state';
import {mutations} from './mutations';
import {actions} from './actions';
import {PlantaState} from './types';
import {RootState} from '@/store/types';
const namespaced: boolean=true;
export const plantaModule:Module<PlantaState,RootState>={
    namespaced,
    state,
    actions,
    mutations,
}