import {Module} from 'vuex';
import {state} from './state';
import {mutations} from './mutations';
import {actions} from './actions';
import {HaciendaState} from './types';
import {RootState} from '@/store/types';
const namespaced: boolean=true;
export const haciendaModule:Module<HaciendaState,RootState>={
    namespaced,
    state,
    actions,
    mutations,
}