import { MaestroForestal} from './types'
import {MutationTree} from 'vuex';
import { Espesor } from '@/entidades/Maestro/Ktb/Espesor';
import { Largo } from '@/entidades/Maestro/Ktb/Largo';
import { Defecto } from '@/entidades/Maestro/Ktb/Defecto';
import { TipoMadera } from '@/entidades/Maestro/Ktb/TipoMadera';
import { Origen } from '@/entidades/Maestro/Ktb/Origen';
import { Formato } from '@/entidades/Maestro/Ktb/Formato';
import Empleado from '@/entidades/Plantacion/Empleado';
import { Linea } from '@/entidades/Maestro/Ktb/Linea';
import { Material } from '@/entidades/Maestro/Ktb/Material';
import { Mesa } from '@/entidades/Maestro/Ktb/Mesa';
import { TurnoQuery } from '@/entidades/KTB/RegistroInformacion/Turno';
import HaciendaItem from '@/entidades/Plantacion/HaciendaItem';
import Labor from '@/entidades/Plantacion/Labor';
import TipoLabor from '@/entidades/Plantacion/TipoLabor';
import { Cuadrilla, TipoCuadrilla } from '@/entidades/Plantacion/Cuadrilla';
import Contratista from '@/entidades/Plantacion/Contratista';
import { LaborModel } from '@/entidades/Plantacion/Partes/LaborModel';
import { ParteDetalleModel } from '@/entidades/Plantacion/Partes/ParteModel';
type ForestalMutation = MutationTree<MaestroForestal>;


export const mutations: ForestalMutation={
    setLabor(state:MaestroForestal,payload:Array<LaborModel>)
    {
        state.labores  = payload;
    },
    setLaborCantidad(state:MaestroForestal,payload:ParteDetalleModel)
    {
        var labor = state.labores.find(c=>c.laborId== payload.laborId && c.haciendaId == payload.haciendaId
        && c.loteId == payload.loteId);
        var index = state.labores.indexOf(labor!);
        state.labores[index].cantidad -= payload.cantidad;
        state.labores[index].cantidad -= payload.cantidadPlantula;
    },
    setLaborCantidadAumentar(state:MaestroForestal,payload:ParteDetalleModel)
    {
        var labor = state.labores.find(c=>c.laborId== payload.laborId && c.haciendaId == payload.haciendaId
            && c.loteId == payload.loteId);
            var index = state.labores.indexOf(labor!);
            state.labores[index].cantidad += Number(payload.cantidad);
            state.labores[index].cantidad += Number(payload.cantidadPlantula);
    },
    setTipoLabor(state:MaestroForestal,payload:Array<TipoLabor>)
    {
        state.tipoLabores  = payload;
    },
    setCuadrilla(state:MaestroForestal,payload:Array<Cuadrilla>)
    {
        state.cuadrillas  = payload;
    },
    setTipoCuadrilla(state:MaestroForestal,payload:Array<TipoCuadrilla>)
    {
        state.tipoCuadrillas  = payload;
    },
    setContratista(state:MaestroForestal,payload:Array<Contratista>)
    {
        state.contratistas  = payload;
    },
    setEmpleado(state:MaestroForestal,payload:Array<Empleado>)
    {
        state.empleados  = payload;
    },
    setHacienda(state:MaestroForestal,payload:Array<HaciendaItem>)
    {
        state.haciendas = payload;
    }
}