






























import moment from 'moment';
import {Vue,Component, Prop} from 'vue-property-decorator';
@Component({
    name : 'Fecha'
})
export default class Fecha extends Vue
{
    @Prop({type:Boolean}) menu!:boolean;
    @Prop({type:String}) label!:string;
    @Prop({type:String,required:false,default:""}) icono!:string;
    @Prop({type:String}) fecha!:string;
    @Prop({type:Boolean}) disabled!:boolean;
    @Prop({type:Boolean}) readonly!:boolean
    @Prop({type:Function}) close!:Function;

    menuLocal:boolean=false;
    fechaLocal:string="";

    get fechaC()
    {
        return moment(new Date()).format("YYYY-MM-DD");
    }

    get menuC()
    {
        var newMenu = this.menu;
        return newMenu;
    }

    get iconoShow()
    {
        if(this.icono !== "")
        {
            return this.icono;
        }
        return "";
    }
    cerrar()
    {
        this.close(this.fechaLocal);
        this.menuLocal = false;
    }
     mounted() {
         this.menuLocal = this.menu;
         this.fechaLocal = this.fecha === "" || this.fecha == undefined ?  moment(new Date()).format("YYYY-MM-DD") : this.fecha;
      //  this.fecha =  moment(new Date()).format("YYYY-MM-DD");  
     }
}
