import {despachoApi} from '@/entidades/Recepcion/Despacho'
import {ActionContext, ActionTree} from 'vuex';
import {DespachoState} from './types'
import {RootState} from '@/store/types'
import {AxiosResponse} from 'axios'
import { secondAxios } from '@/plugins/axios';
import { mensajes } from '@/plugins/notify';
type DespachoActionContext=ActionContext<DespachoState,RootState>;
type DespachoActionTree= ActionTree<DespachoState,RootState>;
export const actions: DespachoActionTree={
    async enviar(context: DespachoActionContext, payload: despachoApi){
        console.log('enviando', payload);
        try{
            const responde: AxiosResponse=await secondAxios({
                url:'RecepcionPlantula/Guardar',
                method:'POST',
                data:payload
            });
            const payload2:any=responde && responde.data;
            //@ts-ignore
            if(payload2.isSuccess){
                //@ts-ignore
                console.log('EXITO')
                mensajes.exito('Datos enviados correctamente');
                context.commit('deleteDespacho2',payload)
            }else{
                mensajes.error('Error al enviar los datos: '+payload2.isMessage);
            }
        } catch(e){
            mensajes.errorDeConexion(); 
        }
    }
}