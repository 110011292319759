import { UsuarioState } from "./types";
import { MutationTree } from "vuex";
type UsuarioMutationTree = MutationTree<UsuarioState>;


export const mutations:UsuarioMutationTree=
{
    usuarioLogin(state,payload)
    {

        state.fullName = payload.fullName;
        state.token = payload.token;
        state.modulos = payload.sistema.modulos;
        state.sistemaId = payload.sistemaId;
        state.userAuth = true;
        state.usuarioId=payload.usuarioId;
        state.rolName = payload.rolName;
        state.perfilName = payload.perfilName;
        state.notificacion = payload.notificacion;
        state.plantaAsignada = payload.sistema.plantaAsignada;
        state.sistemaName = payload.sistemaName;
        localStorage.setItem("token",payload.token);
    },
    usuarioLogout(state)
    {
        state.fullName = "";
        state.token = "";
        state.modulos = [];
        state.sistemaId= 1;
        state.userAuth = false;
        state.rolName = "";
        state.perfilName =  "";
        state.notificacion =0;
        state.usuarioId = "";
        state.error = false;
        state.plantaAsignada = [];
        state.errorMessage ="";
        localStorage.removeItem("token");
        localStorage.removeItem("vuex");
    },


    mostrarError(state,payload)
    {
        state.error = true;
        state.errorMessage = payload;
    },
    actualizarNotificacion(state,payload)
    {
        state.notificacion = payload;
    }

}
