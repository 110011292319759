






























































import {Vue,Component} from  'vue-property-decorator';
//@ts-ignore
import vueperfectscrollbar from 'vue-perfect-scrollbar';
import {State,Getter} from 'vuex-class';
let namespace  ='authModule';
@Component({
    components:{
        vueperfectscrollbar
    }
})
export default class AccountComponent extends  Vue
{
     @State('fullName',{namespace}) fullName!:string;
     @State('rolName',{namespace}) rolName!:string;
     @State('perfilName',{namespace}) perfilName!:string;

}
