















import {Vue,Component} from 'vue-property-decorator';
import Menu from '@/components/Home/Menu.vue';
import {State} from 'vuex-class';
var namespaced = "authModule";
@Component({
  components:{
    Menu
  }
})
export default class HomeView extends Vue
{

   @State('userAuth',{namespace:namespaced}) userAuth!:boolean; 
  
}
