





















































import {Vue,Component,Prop} from 'vue-property-decorator'
import {NotificacionDisponible} from '@/entidades/Plantacion/Partes/NotificacionDisponible';
//@ts-ignore

import vueperfectscrollbar from 'vue-perfect-scrollbar';
@Component({
    components:{
        vueperfectscrollbar
    }
})
export default class NotificacionLeft extends Vue
{
 
  @Prop({type:Array,required:true})
  items!:Array<NotificacionDisponible>
 
  irParte()
  {
      this.$router.push('/aprobaciones')
  }
}
