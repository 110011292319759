import {mainAxios,secondAxios} from '../plugins/axios';
import ResponseCustom from '../entidades/ResponseCustom';
import ResponseGenerico from '../entidades/ResponseGenerico';
import ResponseCustomObject from '@/entidades/ResponseCustomObject';

 async function Obtener<T>(_url:string,axios:any):Promise<ResponseCustom<T>>
{
    var response:ResponseCustom<T> = new ResponseCustom(false,"",new Array<any>());
    try
    {
        let {data} = await axios({
            method : 'GET',
            url : _url
         });
         response = data;
    }
    catch(error)
    {

    }
    return response;

}

async function ObtenerObject<T>(_url:string,axios:any):Promise<ResponseCustomObject<T>>
{
    var response:ResponseCustomObject<T> = new ResponseCustomObject(false,"");
    try
    {
        let {data} = await axios({
            method : 'GET',
            url : _url,
         });
         response = data;
    }
    catch(error)
    {
       return response;
    }
    return response;

}

async function ObtenerObjectCustom<T>(_url:string,axios:any):Promise<T>
{
    var response = null ;
    try
    {
        let {data} = await axios({
            method : 'GET',
            url : _url,
         });
         response = data;
    }
    catch(error)
    {
       return response;
    }
    return response;

}


async function ObtenerPost2<T,T1>(_url:string,query:T1,axios:any):Promise<ResponseCustom<T>>
{
    var response:ResponseCustom<T> = new ResponseCustom(false,"",new Array<T>());
    try
    {
        let {data} = await axios({
            method : 'POST',
            url : _url,
            data : query
         });
         response = data;
    }
    catch(error)
    {

    }
    return response;

}

async function Save<T>(_url:string, entidad:T,axios:any):Promise<ResponseGenerico>
{
    var response:ResponseGenerico = new ResponseGenerico(false,"");
    try
    {
        let {data} = await axios({
            method : 'POST',
            url : _url,
            data : entidad
        });
        response = data;
    }
    catch(error)
    {
    }
    return response;
}


async function Save2<T>(_url:string, entidad:T):Promise<ResponseGenerico>
{
    var response:ResponseGenerico = new ResponseGenerico(false,"");
    try
    {
        let {data} = await secondAxios({
            method : 'POST',
            url : _url,
            data : entidad
        });
        response = data;
    }
    catch(error)
    {
    }
    return response;
}

async function Editar<T>(_url:string, entidad:T,axios:any):Promise<ResponseGenerico>
{
    var response:ResponseGenerico = new ResponseGenerico(false,"");
    try
    {
        let {data} = await axios({
            method : 'PUT',
            url : _url,
            data : entidad
        });
        response = data;
    }
    catch(error)
    {
    }
    return response;
}
async function EditarUrl(_url:string,axios:any):Promise<ResponseGenerico>
{
    var response:ResponseGenerico = new ResponseGenerico(false,"");
    try
    {
        let {data} = await axios({
            method : 'PUT',
            url : _url,
        });
        response = data;
    }
    catch(error)
    {
    }
    return response;
}


async function Eliminar(_url:string):Promise<ResponseGenerico>
{
    var response:ResponseGenerico = new ResponseGenerico(false,"");
    try
    {
        let {data} = await mainAxios({
            method : 'DELETE',
            url : _url,
        });
        response = data;
    }
    catch(error)
    {
        response.isSuccess = false;
       // response.isMessage = error;
    }
    return response;
}


async function SaveList<T>(_url:string, entidad:T[],axios:any):Promise<ResponseGenerico>
{
    var response:ResponseGenerico = new ResponseGenerico(false,"");
    try
    {
        let {data} = await axios({
            method : 'POST',
            url : _url,
            data : entidad
        });
        response = data;
    }
    catch(error)
    {
        response.isSuccess = false;
        //response.isMessage = error.message;
    }
    return response;
}
//Otro obtener
async function Obtener3<T>(_url:string,axios:any):Promise<ResponseCustom<T>>
{
    var response:ResponseCustom<T> = new ResponseCustom(false,"",new Array<any>());
    try
    {
        let {data} = await axios({
            method : 'GET',
            url : _url,
         });
         response = data;
    }
    catch(error)
    {

    }
    return response;

}


async function DescargarFile<T>(_url:string,entidad:T,axios:any)
{
    let {data} = await axios({
        method :'POST',
        url : _url,
        data : entidad,
        responseType : 'blob'
    })
    return data;
}

async function DescargarFileList<T>(_url:string,entidad:T[],axios:any)
{
    let {data} = await axios({
        method :'POST',
        url : _url,
        data : entidad,
        responseType : 'blob'
    })
    return data;
}






async function DescargarFilePdf(_url:string,axios:any)
{
    let {data} = await axios({
        method :'GET',
        url : _url,
       responseType : 'blob'
    })
    return data;
}
export {Obtener,Save,Editar,Eliminar,SaveList,ObtenerObject,Save2,ObtenerPost2,Obtener3,DescargarFile,EditarUrl,DescargarFilePdf,DescargarFileList};
