export default class  Alerta
{
    alertText?:string="";
    alert?:boolean=false;
    color?:string="";
    timeout?:number=0;

    constructor(_alertText?:string,_alert?:boolean,_color?:string,_timeout?:number)
    {
        this.alertText=_alertText;
        this.alert = _alert;
        this.color = _color;
        this.timeout = _timeout;
    }
}