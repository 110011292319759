




























import {Component, Prop, Vue} from 'vue-property-decorator'
@Component
export default class IconoTooltip extends Vue 
{
    @Prop({type:String,required:true}) mensaje!:string;
    @Prop({type:String,required:true}) icono!:string;
    @Prop({type:String,required:true}) color!:string;
    @Prop({type:Boolean,required:false}) disabled!:boolean;
    @Prop({type:Boolean,required:false,default:false}) texto!:boolean;
    @Prop({type:String,required:false,default:''}) textoButton!:string;
    @Prop({type:String,required:false,default:''}) classButton!:string;

    get textoCustomer()
    {
      if(this.texto === true)
      {
        return this.textoButton
      }
      else
      {
        return "";
      }
    }

    get classCustomer()
    {
      if(this.classButton === "")
      {
        return "";
      }
      else
      {
        return this.classButton;
      }
    }

    get iconCustomer()
    {
      if(this.textoButton === "")
      {
        return true;
      }
      else 

      {
        return false;
      }
    }

    get colorCustomerButton()
    {
      if(this.textoButton === "")
      {
        return "";
      }
      else 

      {
        return this.color;
      }
    }

     get colorCustomerIcon()
    {
      if(this.textoButton === "")
      {
        return this.color;
      }
      else 

      {
        return "white";
      }
    }
}
