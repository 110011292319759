export default class ResponseGenerico
{

    public isSuccess:boolean=false;
    public isMessage:string="";

    constructor(_isSuccess:boolean,_isMessage:string)
    {
        this.isSuccess = _isSuccess;
        this.isMessage = _isMessage;
    }
}