import {UsuarioState} from './types';
import {Planta} from "@/entidades/Maestro/Planta";
export const state: UsuarioState=
{
     fullName:"",
     modulos :[],
     sistemaId:0,
     token:"",
     error:false,
     errorMessage:"",
     userAuth :false,
     usuarioId:'',
     notificacion:0,
     rolName:'',
     perfilName:'',
     plantaAsignada : [],
     sistemaName : ""
}