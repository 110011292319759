import Vue from 'vue'
export class mensajes{
    static error(message: string){
        Vue.notify({
            group: 'foo',
            title: '',
            text: message,
            type:'error'
        });
    }
    static errorDeConexion(){
        this.error('No se pudo establecer conexión con el servidor')
    }
    static exito(message: string){
        Vue.notify({
            group: 'foo',
            title: '',
            text: message,
            type:'success'
        });
    }
    static advertencia(message: string){
        Vue.notify({
            group: 'foo',
            title: '',
            text: message,
            type:'warn'
        });
    }
}