import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';


//Vue.use(VueAxios,axios);


const mainAxios = axios.create({
  //baseURL: 'http://localhost:5001/api/',
  // baseURL : 'http://18.191.41.241:1001/api/'
  baseURL: 'https://admin.forestal40.com/api/'
});
const secondAxios = axios.create({
  ///api/'
  // baseURL: 'http://192.168.100.8:6071/api/'
  //url del servidor
 // baseURL : 'http://localhost:6071/api/'
  baseURL: 'https://forestal.forestal40.com/api/'
});
const reporteriaAxios = axios.create({
  //baseURL: 'http://localhost:6072/api/'
  //baseURL: 'https://localhost:44310/api/'
  baseURL: 'http://18.191.41.241:1003/api/'

});

const axiosKtb = axios.create({
  //baseURL : 'https://localhost:44381/api/'
  //baseURL : 'http://localhost:1004/api/'
  baseURL: 'https://ktb.forestal40.com/api/'
});
export { mainAxios, secondAxios, reporteriaAxios, axiosKtb };
