



























import { Children } from '@/store/Auth/types';
import {Vue,Component,Prop} from 'vue-property-decorator';
@Component
export default class Item extends Vue
{
    @Prop({type:Array})
    item!:Children
}
