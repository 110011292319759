import {Obtener} from '@/Generico/Conexion';
import ResponseGenerico from '../../ResponseGenerico';
import {secondAxios} from '@/plugins/axios';
class NotificacionDisponible
{
    parteId:string="";
    haciendaName:string="";
    fecha:string="";
    costo:number=0;
}


async function ObtenerNotificacion(usuarioId:string)
{
    return await Obtener<NotificacionDisponible>(`parte/ObtenerNotificacionDetalle/${usuarioId}`,secondAxios);
}

async function ObtenerCount(usuarioId:string)
{
    let count :number =0;
    try
    {
        let response = await Obtener<NotificacionDisponible>(`parte/ObtenerNotificacionDetalle/${usuarioId}`,secondAxios);
        if(response.isSuccess == true)
        {
            count = response.isResult.length;
        }
    }
    catch(error)
    {
        console.log(error);
    }

    return count;
   
}
export 
{
 NotificacionDisponible,
 ObtenerNotificacion,
 ObtenerCount

}