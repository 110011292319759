import SistemaParte from './types';
import { RootState } from '../types';
import {ActionContext, ActionTree} from 'vuex';

type SistemaActionContext=ActionContext<SistemaParte,RootState>;
type SistemaActionTree = ActionTree<SistemaParte,RootState>;

export const actions: SistemaActionTree={
    changeThemeMode(context:SistemaActionContext ) {
        context.commit("toggleThemeMode");
      },
      changeThemeLayout(context:SistemaActionContext, data) {
        context.commit("toggleThemeLayout", data);
      },
      changeThemeDarkMode(context:SistemaActionContext, data) {
        context.commit("toggleThemeDarkMode", data);
      },
      changeThemeRtl(context:SistemaActionContext) {
        context.commit("toggleThemeRtl");
      },
      changeVerticalSidebarDrawer(context:SistemaActionContext) {
        context.commit("toggleVerticalSidebarDrawer");
      },
      changeVerticalSaasSidebarDrawer(context:SistemaActionContext) {
        context.commit("toggleVerticalSaasSidebarDrawer");
      },
      changeVerticalSidebarDrawerColor(context:SistemaActionContext, data) {
        context.commit("toggleVerticalSidebarDrawerColor", data);
      },
      changeAppBarColor(context:SistemaActionContext, data) {
        context.commit("toggleAppBarColor", data);
      },
      changeCompactVerticalSidebarDrawer(context:SistemaActionContext) {
        context.commit("toggleCompactVerticalSidebarDrawer");
      },
      changeVerticalSidebarMini(context:SistemaActionContext) {
        context.commit("toggleVerticalSidebarMini");
      },
    
      changeThemeLoadingState(context:SistemaActionContext, payload) {
        context.commit("toggleThemeLoadingState", payload);
      },

      async changeLoading(context:SistemaActionContext, payload) {
        context.commit("mostrarLoading", payload);
      },

      async changeAlerta(context:SistemaActionContext, payload) {
        context.commit("mostarAlert", payload);
      }
}