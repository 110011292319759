import { ItemParteModel } from "@/entidades/Plantacion/Partes/ItemParteModel";
import { LaborModel } from "@/entidades/Plantacion/Partes/LaborModel";
import { ParteApiModel } from "@/entidades/Plantacion/Partes/ParteApiModel";
import { ParteModel } from "@/entidades/Plantacion/Partes/ParteModel";
import { TipoCuadrilla } from "@/entidades/Plantacion/Cuadrilla";
import Hacienda from "@/entidades/Plantacion/Hacienda";
import TipoLabor from "@/entidades/Plantacion/TipoLabor";
import { IParteCabeceraAprobacion, ParteState } from "./types";

export const state: ParteState=
{
    partes:new Array<ItemParteModel>(),
    haciendas:new Array<Hacienda>(),
    labores:new Array<LaborModel>(),
    partesPendientesDeAprobacion:new Array<IParteCabeceraAprobacion>(),
    partesEnviados:new Array<ParteApiModel>(),
    tipoLabor:new Array<TipoLabor>(),
    partesLocales:new Array<ParteModel>(),
    tipoCuadrilla:new Array<TipoCuadrilla>(),
    contratistas:[],
    cuadrillas:[]

}