import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import vuexLocal from '@/plugins/vuex-persist'
import {RootState} from '@/store/types'
import {haciendaModule} from '@/store/Maestros/Hacienda'
import {despachoModule} from '@/store/Maestros/Despachos'
import {plantaModule} from '@/store/Maestros/Plantas'
import {parteModule} from '@/store/Parte'
import {sistemaModule} from './Sistema/index';
import {authModule} from './Auth/index';
import {verdeModule}from '@/store/MaderaVerde/RecepcionDespacho';
import {maestroVerde} from '@/store/MaderaVerde/Maestro';
import {maestroKtb} from '@/store/Ktb/Maestro'
import {maestroForestal} from '@/store/Forestal/Maestro';
import {laboresForestal} from '@/store/Forestal/Labores';
Vue.use(Vuex)
const store: StoreOptions<RootState>={
  state: {
    appName:'SISTEMA INTEGRAL',
    appVersion:'0.0.1',
  },
  modules:{
    haciendaModule,
    despachoModule,
    plantaModule,
    parteModule,
    sistemaModule,
    authModule,
    verdeModule,
    maestroVerde,
    maestroKtb,
    maestroForestal,
    laboresForestal
  },
  plugins:[vuexLocal.plugin]
};
export default new Vuex.Store<RootState>(store);

//export default store;