import { MaestroForestal } from "./types";

export const state: MaestroForestal={
    empleados :[],
    haciendas:[],
    cuadrillas:[],
    tipoCuadrillas:[],
    tipoLabores:[],
    contratistas :[],
    labores:[]
}