import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import HomeKtb from '../views/HomeKtb.vue'
import store from '@/store';
//@ts-ignore
var sistema = store.state.authModule.sistemaId;
var rutaRedirect = "";

if(sistema  == 1)
{
  rutaRedirect = "FORESTAL";
}
else if (sistema == 2)
{
  rutaRedirect = "KTB";
}
else if(sistema == 3)
{
  rutaRedirect = "MADERAVERDE";
}
else if(sistema == 4)
{
  rutaRedirect = "ADMINISTRACIONPAGO";
}


Vue.use(VueRouter)
var routes :Array<RouteConfig> =[
{
    path :'/',
    redirect : rutaRedirect
  },
 //RUTAS DIRECTAS
  //RUTA DE KTB
  {
    path:'/ktb/',
    name : 'KTB',
    component : HomeKtb,
    children:[
      {path: 'aperturaTurno', component: () => import('@/views/Ktb/Operaciones/AperturaTurno.vue'),meta:'Apertura Turno'},
      {path: 'defecto',component: () => import('@/views/Ktb/Maestro/Defecto.vue'),meta:'Defecto'},
      {path: 'espesor',component: () => import( '@/views/Ktb/Maestro/Espesor.vue'),meta:'Espesor'},
      {path: 'formato',component: () => import('@/views/Ktb/Maestro/Formato.vue'),meta:'Formato'},
      {path: 'largo',component: () => import('@/views/Ktb/Maestro/Largo.vue'),meta:'Largo'},
      {path: 'material',component: () => import('@/views/Ktb/Maestro/Material.vue'),meta:'Material'},
      {path: 'origen',component: () => import('@/views/Ktb/Maestro/Origen.vue'),meta:'Origen'},
      {path: 'linea',component: () => import('@/views/Ktb/Maestro/Linea.vue'),meta:'Linea'},
      {path: 'mesa',component: () => import('@/views/Ktb/Maestro/Mesa.vue'),meta:'Mesa'},
      {path: 'tipomadera',component: () => import('@/views/Ktb/Maestro/TipoMadera.vue'),meta:'Tipo Madera'},
      {path: 'hacienda',component: () => import('../views/Hacienda.vue'),meta:'Hacienda'},
      {path: 'haciendaItem',component: () => import('@/views/HaciendaItem.vue'),meta:'HaciendaItem'},
      {path: 'usuario',component: () => import( '../views/Usuario.vue'),meta:'Usuario'},
      {path: 'recepcionktb',component: () => import('@/views/Ktb/Operaciones/RecepcionMadera.vue'),meta:'Recepcion Madera'},
      {path: 'turno',component: () => import('@/views/Ktb/Operaciones/Turno.vue'),meta:'Turno'},
      {path: 'planificacion',component: () => import('@/views/Ktb/Operaciones/Planificacion.vue'),meta:'Planificacion'},
      {path: 'consumo',component: () => import('@/views/Ktb/Operaciones/Consumo.vue'),meta:'Consumo'},
      {path: 'produccion',component: () => import('@/views/Ktb/Operaciones/Produccion.vue'),meta:'Produccion'},
      {path: 'despachoKtb',component: () => import('@/views/Ktb/Operaciones/Despacho.vue'),meta:'Despacho'},
      {path: 'despachorechazado',component: () => import('@/views/Ktb/Operaciones/DespachoRechazado.vue'),meta:'Despacho Rechazado'},
      {path: 'perfilUsuario',component: () => import('@/views/Seguridad/ProfileUsuario.vue'),meta:'Perfil de Usuario'},
      {path: 'produccionMesa',component: () => import('@/views/Ktb/Consulta/ProduccionMesa.vue'),meta:'Produccion Mesa'},
      {name: 'ProduccionMesaPlanta',path: 'produccionMesaPlanta/:id', component: () => import('@/views/Ktb/Consulta/ProduccionMesaPlanta.vue'),meta:'Produccion Mesa'},
      {path: 'empleado',component: () => import('@/views/Forestal/Plantacion/Empleado.vue'),meta:'Empleado'},
      {path: 'contratista',component: () => import('@/views/Forestal/Plantacion/Contratista.vue'),meta:'Contratista'},
      {path: 'contratistaPo',component: () => import('@/views/Forestal/Plantacion/ContratistaPo.vue'),meta:'ContratistaPo'},
       //consulta
       {path: 'despachoVerde', component: () => import('@/views/Ktb/Consulta/DespachoVerde.vue'),meta:'Consulta Despacho'},
       {path: 'receptionTally', component: () => import('@/views/Ktb/Consulta/RecepcionTally.vue'),meta:'Consulta Recepcion'},
       {path: 'consumoConsulta', component: () => import('@/views/Ktb/Consulta/Consumo.vue'),meta:'Consulta Consumo'},
    ]
  },
  {
    path : '/forestal/',
    name : 'FORESTAL',
    component: Home,
    children:
    [
      {path: '',component: () => import('@/components/Forestal/Home/HomeComponent.vue'),meta:'HomePrincipalForestal' , name : 'HomePrincipalForestal'},
      {path: 'unidadmedida',component: () => import('@/views/Forestal/Plantacion/UnidadMedida.vue'),meta:'Unidad de Medina'},
      {path: 'tipolabor',component: () => import('@/views/Forestal/Plantacion/TipoLabor.vue'),meta:'Tipo Labor'},
      {path: 'labor',component: () => import('@/views/Forestal/Plantacion/Labor.vue'),meta:'Labor'},
      {path: 'empleado',component: () => import('@/views/Forestal/Plantacion/Empleado.vue'),meta:'Empleado'},
      {path: 'contratista',component: () => import('@/views/Forestal/Plantacion/Contratista.vue'),meta:'Contratista'},
      {path: 'transportista',component: () => import('@/views/Forestal/Plantacion/Transportista.vue'),meta:'Transportista'},
      {path: 'cuadrilla',component: () => import('@/views/Forestal/Plantacion/Cuadrilla.vue'),meta:'Cuadrilla'},
      {path: 'aprobacion',component: () => import('@/views/Forestal/Plantacion/Aprobacion.vue'),meta:'Aprobacion'},
      {path: 'tarifa',component: () => import('@/views/Forestal/Plantacion/Tarifa.vue'),meta:'Tarifa'},
      {path: 'hacienda',component: () => import('@/views/Hacienda.vue'),meta:'Hacienda'},
      {path: 'haciendaItem',component: () => import('@/views/HaciendaItem.vue'),meta:'HaciendaItem'},
      {path: 'haciendaItemLote',component: () => import('@/views/HaciendaItemLote.vue'),meta:'HaciendaItemLote'},
      {path: 'recepcion',component: () => import( '../views/Forestal/Recepcion/RecepcionPage.vue'),meta:'Recepcion Plantula'},
      {path: 'partes',component: () => import( '../views/Forestal/Parte/Partes.vue'),meta:'Partes'},
      {path: 'aprobaciones',component: () => import('../views/Forestal/Parte/Aprobaciones.vue'),meta:'Aprobaciones'},

      {path: 'consultaPartes',component: () => import('../views/ConsultaEstadoParte.vue'),meta:'Estado de Parte'},
      {path: 'despachoAlmacigueras',component: () => import('../views/DespachoAlmacigueras.vue'),meta:'Despacho Almacigueras'},
      {path: 'reporteAlmacigueras',component: () => import( '../views/Forestal/Reportes/ReportesAlmaciguera.vue'),meta:'Reporte Almaciguera'},
      {path: 'reporteRecepcion',component: () => import('../views/Forestal/Reportes/ReporteRecepcionPlantulas.vue'),meta:'Reporte Plantulas' },
      {path: 'reporteCostoFormacion',component: () => import('../views/Forestal/Reportes/ReporteCostoFormacionHacienda.vue'),meta:'Reporte Costo Formacion'},
      {path: 'reporteHectareasTrabajadas',component: () => import('../views/Forestal/Reportes/ReporteHorasMayordomo.vue'),meta:'Reporte Mayodormo'},
      {path: 'mayordomousuario',component: () => import('../views/Forestal/Plantacion/AsignacionMayordomo.vue'),meta:'Asignacion Mayodormo'},
      {path: 'ciclo',component: () => import('../views/Forestal/Plantacion/Ciclo.vue'),meta:'Ciclo'},
      {path: 'parterechazados',component: () => import('../views/Forestal/Parte/ParteRechazado.vue'),meta:'Parte Rechazado'},
      {path: 'asistenciaCuadrilla',name:'AsistenciaCuadrilla', component: () => import('../views/Forestal/Operacion/AsistenciaCuadrilla.vue'),meta:'Asistencia Cuadrilla'},
      {path: 'usuario', component: () => import( '../views/Usuario.vue'),meta:'Usuario'},
      {path: 'consultapartemayordomo', component: () => import( '../views/Forestal/Parte/ConsultaParteMayordomo.vue'),meta:'Usuario'},
      {path: 'planificacion-forestal', component: () => import( '../views/Forestal/Operacion/Planificacion.vue'),meta:'planificacion-forestal'},
    ]
  },
  {
    path: '/maderaverde/',
    name : 'MADERAVERDE',
    component : Home,
    children:
    [
      {path: 'recepcionverde',component: () => import('@/views/MaderaVerde/Operacion/RecepcionKtb.vue'),meta:'Recepcion KTB'}
    ]
  },
  {
    path: '/administracionPago/',
    name : 'ADMINISTRACIONPAGO',
    component : Home,
    children:[
      {path : 'pagoProveedor' , component : () => import('@/views/AdministracionPago/Pago.vue'),meta:'Pago'}
  ]
  },

  /*{
    path: '/ktb',
    component: Home,
    children:[
    {
      path: '/ktb',
      component: HomeKtb,
      children:[

        {path: 'aperturaTurno', component: () => import()},
        {path: 'aperturaTurno2', component: () => import('@/views/Ktb/Operaciones/AperturaTurno.vue')}
      ]
    },

  {
    path: '/home',
    name: 'HomeCustomer',
    component: () => import('../views/Home/About.vue')
  },
  {
    path: '/profileuser',
    name: 'ProfileUser',
    component : ()=> import('@/views/Seguridad/ProfileUsuario.vue')
  },
  {
    path: '/modulo',
    name: 'Modulo',
    component: () => import('../views/Modulo.vue')
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import('../views/Menu.vue')
  },


  {
    path: '/perfil',
    name: 'Perfil',
    component: () => import( '../views/Perfil.vue')
  }
  ,
  {
    path: '/notificacion',
    name: 'Notificacion',
    component: () => import('../views/Notificacion.vue')
  },

  //plantaciones,

  //Recepcion

  {
    path: '/mapas',
    name: 'Mapas',
    component: () => import('../views/Forestal/Plantacion/Mapa.vue')
  },
  //KTB
  ,

  //Voy ponerlo aqui temporalmente la idea es luego tener un home solo para ktb

  //MADERA VERDE

    ],

},*/

{path:'/login',name:'Login',component:()=>import('../views/Auth/Login.vue')},
{path:'/notfound',name:'NotFound',component:()=>import('../views/Auth/NotFound.vue')},
{path: '/forestal/aprobacionCorreo/:parteId/:aprobacionId/:usuarioId/:nivelActual/:nivelMaximo/:tipo', name:'AprobacionOnline', component: () => import('../views/Forestal/Parte/AprobacionCorreo.vue'),meta:'AprobacionCorreo'},

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next) =>{

  let token = localStorage.getItem("token")
  if(!to.matched.length)
  {
     next({name: 'NotFound'})
  }
  else
  {

    if(to.name === "AprobacionOnline")
    {
      next();
    }
    else
    {
      if(to.name !== 'Login' && !token)
    {
      next({name:'Login'})

    }

  else
      next();
    }

  }

})

router.afterEach((to,from) =>{
  document.title = `${rutaRedirect}-${to.meta}`

})

export default router
