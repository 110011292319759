import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import MensajeAlert from './components/General/MensajeAlert.vue';
import Fecha from '@/components/Base/Fecha.vue'
import Loading from '@/components/General/Loading.vue';
import IconoTooltip from '@/components/General/IconoTooltip.vue';
import Vuelidate from 'vuelidate';
import "vue-navigation-bar/dist/vue-navigation-bar.css";
//@ts-ignore
import VueNavigationBar from "vue-navigation-bar";
import '@/styles.css';
import Notifications from 'vue-notification'
import './plugins/app'
//Capacitor 
//import  PalletHub from  './plugins/palletHub';

Vue.config.productionTip = false

//Vue.use(PalletHub);
Vue.component('MensajeAlert',MensajeAlert);
Vue.component('Loading',Loading);
Vue.component('TooltipIcono',IconoTooltip);
Vue.component("vue-navigation-bar", VueNavigationBar);
Vue.component('Fecha',Fecha);
Vue.use(Vuelidate);
Vue.use(Notifications);


//firebase.initializeApp(firebaseConfig);
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
