export default class ResponseCustomObject<T>
{
    public isSuccess:boolean;
    public isMessage:string;
    public isResult?:T;

    constructor(_isSuccess:boolean,_isMessage:string,_isResult?:T)
    {
        this.isSuccess = _isSuccess;
        this.isMessage = _isMessage;
        this.isResult = _isResult;
    }
    

}