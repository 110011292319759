import { Module } from "vuex";
import {state} from './state';
import {actions} from './actions';
import {mutations} from './mutations';
import { UsuarioState } from "./types";
import { RootState } from "../types";

const namespaced:boolean = true;

export const authModule:Module<UsuarioState,RootState>={
    namespaced,
    state,
    actions,
    mutations
}