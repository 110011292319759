//@ts-ignore
import colors, { Color } from "vuetify/lib/util/colors";
const mq = window.matchMedia("(prefers-color-scheme: dark)");
class ThemeConfig 
{
    disableCustomizer:boolean =false;
    rtl:boolean=false;
    verticalSidebarMini:boolean=false;
    verticalCompactSidebarDrawer:boolean=false;
    verticalSaasSidebarDrawer:boolean=false;
    verticalSidebarDrawer:boolean= true;
    showBreadcrumb:boolean=false;
    layout:string="VerticalWhiteSidebar";
    isLoading:boolean=false;
    isDark:boolean=false;
    verticalSidebarDrawerColor:string="white";
    appBarColor : string="white";
}


class Thresholds
{
   xs:number = 600;
   sm:number = 960;
   md:number = 1280;
   lg:number = 1920;
}
class Breakpoint
{
  scrollBarWidth:number = 16;
  thresholds:Thresholds = new Thresholds();
}

class Light
{
  primary:Color= colors.blue;
  secondary:string= "#304156";
  success:Color= colors.green;
  danger:Color= colors.red;
  warning:Color= colors.deepOrange;
  info:Color= colors.indigo;
  dark:string= "#242939";
  background:string= "#f2f3f8"
}

class Dark
{
  primary:Color= colors.blue;
  secondary:string= "#304156";
  success:Color= colors.green;
  danger:Color= colors.red;
  warning:Color= colors.deepOrange;
  info:Color= colors.indigo;
}
class Options
{
  cspNonce: undefined;
  customProperties:boolean = true;
  minifyTheme: undefined;
  themeCache: undefined;
}

class Theme
{
    dark:boolean = mq.matches;
    default:string ="light";
    disable:boolean = false;
    themes:Themes = new Themes();
}
class Themes
{
  options:Options = new Options();
  light:Light = new Light();
  dark:Dark = new Dark();
}

class ThemePresent 
{
  breakpoint:Breakpoint = new Breakpoint();
  rtl:boolean=false;
  theme:Theme = new Theme();

} 


export 
{
  ThemePresent,
  ThemeConfig
}